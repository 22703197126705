import { useEffect, useRef, useState } from 'react';

export interface VideoPlayerProps {
  title?: string;
  thumbnailUrl?: string;
  videoUrl?: string;
}

const VideoPlayer = (props: VideoPlayerProps) => {
  const [playVideo, setPlayVideo] = useState<boolean>(false);
  const refPlayer = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (playVideo) {
      refPlayer.current.src += refPlayer.current.src.indexOf('?') > 0 ? '&autoplay=1' : '?autoplay=1';
    }
  }, [playVideo]);

  const handlePlayVideo = () => {
    setPlayVideo(true);
  };

  return (
    <div className="video content-view-video">
      <div className="video-embed backdrop-blur-md" onClick={() => handlePlayVideo()}>
        {!playVideo && (
          <div className="video-embed--play">
            <img src={props.thumbnailUrl} alt={props.title} />
          </div>
        )}
        {(playVideo || !props.thumbnailUrl) && (
          <iframe
            ref={refPlayer}
            width="560"
            height="315"
            src={props.videoUrl}
            title={props.title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}
      </div>
    </div>
  );
};

export { VideoPlayer };
