import { Button } from '~/components/commons/Button';
import { Icon } from '~/components/commons/Icon';
import Image from '~/components/commons/Image';
import type { NotFoundData } from '~/models/not-found.model';

const NotFoundPage = (props: { data?: NotFoundData }) => {
  return (
    <div className="error s-parent">
      <div className="wrapper flex flex-col items-center px-4 py-8 md:py-48 gap-8">
        <div className="message-container flex flex-col-reverse md:flex-row items-center gap-8 md:gap-16 mb-8">
          <div className="message flex flex-col items-center">
            <div className="title">{props.data?.errorCode}</div>
            <div className="description">{props.data?.errorMessage}</div>
          </div>
          <Image
            className="w-64 md:w-80 pointer-events-none"
            width="400"
            src={props.data?.errorImage?.src}
            alt={props.data?.errorImage?.alt}
          />
        </div>
        <div className="return flex flex-col md:flex-row gap-4 md:gap-2">
          <Button type="link" to={props.data?.returnToButton?.url} className="flex flex-row gap-4 items-center">
            <Icon name="arrow-left" type="solid" className="text-sm" /> {props.data?.returnToButton?.text}
          </Button>
          {props.data?.actionButtons?.map((button, index) => (
            <Button key={index} type={button.type ? button.type : 'link'} variant={button.variant} to={button.url}>
              {button.text}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};

export { NotFoundPage };
