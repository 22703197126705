import type { Icon as IconProps } from '~/models/icon.model';

const styles = {
  solid: 'fas',
  regular: 'far',
  brands: 'fa-brands',
};

const Icon = ({ name, type = 'solid', className, ...inner }: IconProps) => {
  let classBase = [styles[type], 'fa-' + name];

  if (className) {
    classBase = [...classBase, ...className.split(' ')];
  }

  return (
    <>
      <i className={classBase.join(' ')} {...inner}></i>
    </>
  );
};

const EnterIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M10 9l-6 6 6 6" />
        <path d="M20 4v7a4 4 0 0 1-4 4H5" />
      </svg>
    </>
  );
};

export { Icon, EnterIcon };
