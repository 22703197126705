import type { ReactNode } from 'react';
import { useRef, useState } from 'react';
import { Button } from './Button';
import { Icon } from './Icon';

type ModalProps = {
  open: boolean;
  close: () => void;
  children: ReactNode | ReactNode[];
};

const Modal = ({ open, close, children }: ModalProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [outside, setOutside] = useState<boolean>(false);

  function exitModal() {
    setOutside(true);
    setTimeout(() => {
      setOutside(false);
      return close();
    }, 400);
  }

  const handleKeyEscape = () => {
    if (typeof window !== 'undefined') {
      document.onkeydown = function (ke: KeyboardEvent) {
        ke = ke || window.event;
        if (ke.key === 'Escape') {
          exitModal();
        }
      };
    }
  };

  const handleClick = (e: { target: any }) => {
    if (ref.current && !ref.current.contains(e.target)) {
      exitModal();
    }
  };

  if (!outside) {
    handleKeyEscape();
  }

  return (
    <>
      {open && (
        <div
          className={`modal-frame fixed top-0 left-0 !w-full h-screen z-50 bg-black/80 flex justify-center items-center ${
            outside ? 'exit' : ''
          }`}
          onClick={handleClick}
        >
          <div className="close absolute top-16 right-2 md:top-8 md:right-8">
            <Button variant="none" onClick={handleClick}>
              <Icon type="solid" className="text-xl text-white" name="close" />
            </Button>
          </div>
          <div ref={ref} className="modal-wrapper w-full max-w-4xl mx-2 sm:mx-8 lg:mx-0">
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
