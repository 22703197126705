export type ImageProp = {
  src?: string;
  alt?: string;
  grayscale?: boolean;
  className?: string;
  width?: string;
  height?: string;
}

const Image = (props: ImageProp) => {
  const className = props.className ? props.className : '';

  return (
    <img
      src={props.src}
      alt={props.alt}
      className={`${className} ${props.grayscale ? 'grayscale brightness-75' : ''}`}
      width={props.width}
      height={props.height}
    />
  );
};

export default Image;
