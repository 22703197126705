import { Link } from '@remix-run/react';
import Modal from '../commons/Modal';
import { useState } from 'react';
import { VideoPlayer } from '../commons/VideoPlayer';
import type { ReactNode } from 'react';
import type { VideoPlayerComponent } from '~/models/video-player.model';
import { Icon } from './Icon';

type ButtonProps = {
  variant?: 'solid' | 'outline' | 'text' | 'square' | 'none';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'square' | 'none';
  type?: 'button' | 'link' | 'submit' | 'modal';
  disabled?: boolean;
  className?: string;
  children: ReactNode | ReactNode[];
  to?: string;
  target?: string;
  video?: VideoPlayerComponent;
  onClick?: (e?: any) => void;
};

const variants = {
  solid: 'button button-solid',
  outline: 'button button-outline',
  square: 'button button-square',
  text: 'button button-text',
  none: 'button',
};

const sizes = {
  none: 'text-base p-0',
  square: 'text-base p-1.5',
  xs: 'text-sm md:text-xs p-2.5',
  sm: 'text-base md:text-sm px-4 py-2.5',
  md: 'text-base px-4 py-2.5',
  lg: 'text-lg px-5 py-2.5',
};

function Button({
  variant = 'solid',
  size = 'md',
  type = 'button',
  disabled = false,
  to,
  target,
  className,
  children,
  video,
  onClick,
  ...more
}: ButtonProps) {
  const [open, setOpen] = useState(false);
  const classDisabled = disabled ? 'opacity-50 pointer-events-none' : '';
  let classBase = [variants[variant], sizes[size], classDisabled];

  if (className) {
    classBase = [...classBase, ...className.split(' ')];
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {type === 'link' ? (
        to ? (
          <Link
            to={to}
            target={to.startsWith('/') ? '_self' : '_blank'}
            className={classBase.join(' ')}
            {...more}
          >
            {children}
            {variant === 'text' && <span className="mt-0.5">
              <Icon type="solid" name="chevron-right" className="text-xs pl-1" />
            </span>}
          </Link>
        ) : (
          <div className={classBase.join(' ')}>{children}</div>
        )
      ) : type === 'modal' ? (
        <>
          <button type="button" className={classBase.join(' ')} disabled={disabled} onClick={handleOpen} {...more}>
            {children}
          </button>
          <Modal open={open} close={handleClose}>
            <VideoPlayer videoUrl={video?.videoUrl} thumbnailUrl={video?.thumbnailUrl} />
          </Modal>
        </>
      ) : (
        <button type={type} className={classBase.join(' ')} disabled={disabled} onClick={onClick} {...more}>
          {children}
        </button>
      )}
    </>
  );
}

export { Button };
